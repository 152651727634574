<template>
  <div>
    <section class="l-one__col l-one__col--x-wide">
      <Card
        heading="Earth Week is over but our commitment to the planet is in full bloom."
        headlineLevel="h2"
        :maxWidth="1200"
      >
        <template #image>
          <img src="@/assets/images/EarthWeekPromoOver/green-plug.webp" alt="Green plug" />
        </template>
        <p>
          Learn about Electrify America’s commitment to creating a more sustainable and equitable future through
          environmental and social practices.
        </p>
        <router-link class="button-link" :to="{ name: 'our-values-en' }"
          >Learn more about our values<arrow class="arrow"
        /></router-link>
      </Card>
    </section>
  </div>
</template>

<script>
import Card from '@/components/Card/Card.vue';
import arrow from '@/assets/images/Global/arrow.svg';

export default {
  components: { Card, arrow },
  metaInfo: {
    title: 'Celebrating Earth Week | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          "We're committed to making the planet a more sustainable place. Here's how we're serving our communities this Earth Week and year-round.",
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/earth-week/' }],
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .card {
  @media (min-width: 929px) {
    &__image {
      max-width: 465px;
      img {
        max-width: 450px !important;
      }
    }
    &__column {
      max-width: 65%;
    }
  }
}
</style>
